import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Page } from '../../components/Page'
import { YamlViewer } from '../../components/YamlViewer'
import { TokenCard } from '../../components/Tokens'

import { Breadcrumb } from '../../components/Base'

import { GrapheneRegistryService } from '../../services/GrapheneRegistryService'

export const Token = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const [token, setToken] = useState([])
  const [iwaTrustline, setIwaTrustline] = useState([])
  const [cruIds, setCruIds] = useState([])

  useEffect(() => {
    GrapheneRegistryService.getTokenById(id).then((res) => {
      setToken(res.data)
      setIwaTrustline(res.data)
      setCruIds(res.data?.carbonResourceUnits)
    })
  }, [id])

  return (
    <Page>
      {token && (
        <div className="flex flex-col">
          <Breadcrumb
            paths={[
              { name: 'All Tokens', url: '/tokens' },
              { name: token?.tokenSymbol, url: `/tokens/${id}`, active: false },
            ]}
          />

          <TokenCard token={token} key={id} />

          {token?.project && (
            <div className="flex flex-row items-baseline">
              <span className="mr-2">Project:</span>
              <span
                onClick={() => navigate(`/projects/${token?.project.id}`)}
                className="text-lg link-common"
              >
                {token?.project?.project_name}
              </span>
            </div>
          )}

          <div className="flex flex-row mt-2">
            <div className="flex flex-col">
              <h4>Credit Unit IDs</h4>
              <div className="flex flex-wrap">
                {cruIds &&
                  cruIds.map((item, index) => (
                    <div key={index} className="flex flex-row">
                      <div className="p-1 m-1 font-mono text-centigrade-carbon-300 text-xs rounded-lg bg-centigrade-creme-300">
                        {item.credit_unique_id}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col  mt-2">
            <h4>Blockchain Metadata</h4>
            {iwaTrustline && (
              <>
                <YamlViewer hashedInfo={iwaTrustline} />
              </>
            )}
          </div>
        </div>
      )}
    </Page>
  )
}
